/** @jsx jsx */
import { jsx } from '@emotion/core'
import { ButtonGroup, Heading, HFlow, Tooltip, VFlow } from 'bold-ui'
import useSession from 'components/auth/useSession'
import { usePecField } from 'components/form/final-form/hooks/useField'
import { confirm } from 'components/modals/confirm'
import { formatISO } from 'date-fns'
import {
  useAcompanhamentoPuericulturaCardQuery,
  useIvcfEmAtendimentoObservacaoQuery,
  usePreNatalEmAtendimentoObservacaoQuery,
  usePuericulturaQuery,
} from 'graphql/hooks.generated'
import { TipoAtendimentoProfissional } from 'graphql/types.generated'
import { useAtendimentoContext } from 'hooks/atendimento-context/useAtendimentoContext'
import { useFirebase } from 'hooks/firebase/useFirebase'
import { isEmpty } from 'lodash'
import { useCallback, useMemo } from 'react'
import { useField } from 'react-final-form'
import { isCidadaoIdoso } from 'util/isCidadaoIdoso'
import { isCidadaoPuericultura } from 'util/isCidadaoPuericultura'
import { MetaPath } from 'util/metaPath'
import { extractPreNatalFromAtendimentoProfissional } from 'view/atendimentos/atendimento-individual/atendimento-observacao/util-atendObservacao'
import { CidadaoAtendimento } from 'view/atendimentos/types/CidadaoAtendimento'

import { convertAcompanhamentoPuericulturaToModel } from '../../aside/acompanhamento-puericultura/convert'
import { ProblemaCondicaoModel } from '../../avaliacao/components/problemas-condicoes/model-problemasCondicoes'
import {
  informationIvcfQuandoExistePreNatal,
  informationPreNatalQuandoExisteDesfecho,
  informationPreNatalQuandoExisteIvcf,
  informationPreNatalQuandoExistePuericultura,
  informationPuericulturaQuandoExisteDesfecho,
  informationPuericulturaQuandoExistePreNatal,
} from '../../avaliacao/components/problemas-condicoes/utils/messages'
import { hasProblemaCondicaoDePreNatal } from '../../avaliacao/components/problemas-condicoes/utils/verifications-problemasCondicoes'
import { IDADE_GESTACIONAL_MAXIMA_EM_DIAS, MetasPreNatal, TipoPreNatal } from '../../pre-natal/model-preNatal'
import { PreNatalButton } from '../../pre-natal/PreNatalButton'
import { PreNatalField } from '../../pre-natal/PreNatalField'
import { PreNatalView } from '../../pre-natal/PreNatalView'
import {
  informationPreNatalQuandoExisteW78ResolvidoNaAvaliacao,
  informationPreNatalSuperiorA336Dias,
  resetPreNatalValuesToUndefined,
} from '../../pre-natal/util-preNatal'
import { grupoCboPuericultura } from '../acessos'
import { IvcfButton } from '../ivcf/components/IvcfButton'
import { convertIvcfQueryToView } from '../ivcf/converter-ivcf'
import { IvcfField } from '../ivcf/IvcfField'
import { IvcfView } from '../ivcf/IvcfView'
import { IvcfAplicacaoModel, IvcfFieldModel } from '../ivcf/model-ivcf'
import { initialValuesPuericulturaModel, PuericulturaModel } from '../puericultura/model'
import { PuericulturaButton } from '../puericultura/PuericulturaButton'
import PuericulturaField from '../puericultura/PuericulturaField'
import { PuericulturaView } from '../puericultura/PuericulturaView'
import { SwitchPanelObjetivoForm } from './SwitchPanelObjetivoForm'

export enum FormAtivoObjetivoEnum {
  PUERICULTURA = 'PUERICULTURA',
  PRE_NATAL = 'PRE_NATAL',
  IVCF = 'IVCF',
}

export interface SwitchButtonObjetivoFormModel {
  formAtivo: FormAtivoObjetivoEnum
  puericultura: PuericulturaModel
  ivcf: IvcfFieldModel
  ivcfCache?: IvcfAplicacaoModel
}

export interface SwitchButtonObjetivoFormProps {
  name: MetaPath<SwitchButtonObjetivoFormModel>
  metaProblemasCondicoes: MetaPath<ProblemaCondicaoModel[]>
  metasPreNatal: Pick<MetasPreNatal, 'metaDum' | 'metaPreNatal' | 'metaAgendarProximasConsultas'>
  cidadao: CidadaoAtendimento
  prontuarioId: ID
  dataAtendimento: Instant
  tipoPreNatal: TipoPreNatal
  hasProblemaComCiapW78AtivoPersistido: boolean
}

export function SwitchButtonObjetivoForm(props: SwitchButtonObjetivoFormProps) {
  const {
    name,
    cidadao,
    prontuarioId,
    dataAtendimento,
    metasPreNatal: { metaPreNatal, metaDum, metaAgendarProximasConsultas },
    tipoPreNatal,
    metaProblemasCondicoes,
    hasProblemaComCiapW78AtivoPersistido,
  } = props

  const { hasCboAuth } = useSession({ fetchPolicy: 'cache-only' })
  const { analytics } = useFirebase()

  const {
    atendimentoProfissional: { id: atendimentoProfissionalId, tipo: atendimentoProfissionalTipo },
    cidadao: { isGestante, dataInicioGestacao, idadeGestacional, idadeEmAnos },
    permissoes: { hasPermissionPreNatal },
    observacao: { isAtendimentoObservacao, isObservacaoAndAuxiliar },
    tiposAtendimento: { isAtendimentoProcedimentos },
  } = useAtendimentoContext()

  const dataReferencia = formatISO(dataAtendimento, { representation: 'date' })

  const isPreNatalEmAtendimentoObservacao =
    isAtendimentoObservacao && atendimentoProfissionalTipo === TipoAtendimentoProfissional.PRE_NATAL

  const isPuericulturaEmAtendimentoObservacao =
    isAtendimentoObservacao && atendimentoProfissionalTipo === TipoAtendimentoProfissional.PUERICULTURA

  const {
    data: preNatalData,
    loading: isLoadingPreNatalEmAtendimentoObservacao,
  } = usePreNatalEmAtendimentoObservacaoQuery({
    variables: { id: atendimentoProfissionalId },
    skip: !isPreNatalEmAtendimentoObservacao,
  })

  const dadosPreNatal = isPreNatalEmAtendimentoObservacao
    ? extractPreNatalFromAtendimentoProfissional(preNatalData?.atendimentoIndividual)
    : null

  const {
    data: puericulturaData,
    loading: isLoadingAcompanhamentoPuericulturaCardQuery,
  } = useAcompanhamentoPuericulturaCardQuery({
    variables: { prontuarioId, dataReferencia },
    fetchPolicy: 'cache-first',
    skip: !isPuericulturaEmAtendimentoObservacao,
  })

  const acompanhamentoPuericultura = convertAcompanhamentoPuericulturaToModel(puericulturaData, cidadao.dataNascimento)

  const acessoPreNatal = isGestante && hasPermissionPreNatal && !isAtendimentoProcedimentos
  const acessoCboPuericultura = hasCboAuth(grupoCboPuericultura)
  const acessoIvcf = isCidadaoIdoso(idadeEmAnos) && !isObservacaoAndAuxiliar

  const acessoPuericultura = acessoCboPuericultura && isCidadaoPuericultura(idadeEmAnos) && !isAtendimentoProcedimentos

  const { data: ivcfObservacaoData, loading: isLoadingIvcfObservacaoData } = useIvcfEmAtendimentoObservacaoQuery({
    variables: { id: atendimentoProfissionalId },
    skip: !acessoIvcf || !isAtendimentoObservacao,
    fetchPolicy: 'cache-first',
  })

  const hasIvcfPreenchidoEmAtendObservacaoAnterior = useMemo(
    () => !isEmpty(ivcfObservacaoData?.atendimentoIndividual?.ivcf?.id),
    [ivcfObservacaoData]
  )

  const {
    input: { value: puericultura, onChange: updatePuericultura },
  } = useField<PuericulturaModel>(name.puericultura.absolutePath())

  const { loading: isLoadingPuericulturaQuery } = usePuericulturaQuery({
    variables: { prontuarioId, dataReferencia },
    skip: !acessoPuericultura || (!!puericultura && puericultura?.dataNascimentoReferencia === cidadao.dataNascimento),
    onCompleted: (data) =>
      data && updatePuericultura(initialValuesPuericulturaModel(data, cidadao.dataNascimento, dataAtendimento)),
  })

  const {
    input: { value: formAtivo, onChange: updateFormAtivo, onBlur: onBlurFormAtivo },
  } = useField<FormAtivoObjetivoEnum>(name.formAtivo.absolutePath())

  const {
    input: { value: problemasCondicoes },
  } = useField<ProblemaCondicaoModel[]>(metaProblemasCondicoes.absolutePath(), { subscription: { value: true } })

  const {
    tools: { resetToUndefined: resetPreNatal },
  } = usePecField({ name: metaPreNatal.absolutePath(), subscription: { value: true } })

  const {
    input: { value: ivcfValue },
    tools: { resetToUndefined: resetIvcf, resetToInitialValue: resetIvcfToInitialValue },
  } = usePecField({ name: name.ivcf.absolutePath(), subscription: { value: true } })

  const {
    input: { value: ivcfCacheValue },
    tools: { resetToUndefined: resetIvcfCache },
  } = usePecField({ name: name.ivcfCache.absolutePath(), subscription: { value: true } })

  const {
    tools: { resetToUndefined: resetDum },
  } = usePecField({ name: metaDum.absolutePath() })

  const {
    tools: { resetToUndefined: resetAgendarProximasConsultas },
  } = usePecField({ name: metaAgendarProximasConsultas.absolutePath() })

  const setFormAtivo = useCallback(
    (stateButton: FormAtivoObjetivoEnum) => {
      updateFormAtivo(stateButton)
      onBlurFormAtivo()
    },
    [onBlurFormAtivo, updateFormAtivo]
  )

  const setNullIfNewFormAtivoIsEqual = (newFormAtivo: FormAtivoObjetivoEnum) =>
    newFormAtivo === formAtivo ? null : newFormAtivo

  const handleClickPuericultura = (stateButton: FormAtivoObjetivoEnum) => {
    confirm({
      title: 'Deseja descartar os dados de Puericultura?',
      body: 'As alterações realizadas serão perdidas.',
      cancelLabel: 'Não, manter dados',
      confirmLabel: 'Sim, descartar',
      onConfirm: () => {
        setFormAtivo(setNullIfNewFormAtivoIsEqual(stateButton))
      },
    })()
  }

  const isBotaoPreNatalHabilitado = formAtivo === FormAtivoObjetivoEnum.PRE_NATAL
  const isBotaoPuericulturaHabilitado = formAtivo === FormAtivoObjetivoEnum.PUERICULTURA
  const isBotaoIvcfHabilitado = formAtivo === FormAtivoObjetivoEnum.IVCF

  const handleDesabilitarPreNatal = (stateButton: FormAtivoObjetivoEnum) => {
    confirm({
      title: 'Deseja descartar os dados de Pré-natal?',
      body: `Os campos de Pré-natal serão desabilitados, os dados contidos nos mesmos serão perdidos e os problemas e/ou condições de gestação serão excluídos da Avaliação.`,
      cancelLabel: 'Não, manter dados',
      confirmLabel: 'Sim, descartar',
      onConfirm: () => {
        const isPrimeiroAtendimentoPreNatal = tipoPreNatal === TipoPreNatal.PRIMEIRO_ATENDIMENTO_PRE_NATAL

        setFormAtivo(setNullIfNewFormAtivoIsEqual(stateButton))
        resetPreNatalValuesToUndefined({
          resetDum,
          resetPreNatal,
          resetAgendarProximasConsultas,
          isPrimeiroAtendimentoPreNatal,
        })
      },
    })()
  }

  const handleDesabilitarIvcf = (stateButton: FormAtivoObjetivoEnum) => {
    if (!isEmpty(ivcfCacheValue) || !isEmpty(ivcfValue)) {
      confirm({
        title: 'Deseja descartar os dados do IVCF-20?',
        body: 'As alterações realizadas serão perdidas.',
        cancelLabel: 'Não, manter dados',
        confirmLabel: 'Sim, descartar',
        onConfirm: () => {
          analytics.logEvent('click_cancelar_ivcf')
          setFormAtivo(setNullIfNewFormAtivoIsEqual(stateButton))
          resetIvcf()
          resetIvcfCache()
        },
      })()
    } else {
      setFormAtivo(setNullIfNewFormAtivoIsEqual(stateButton))
    }
  }

  const handleHabilitarIvcf = () => {
    analytics.logEvent('click_abrir_ivcf')
    resetIvcfToInitialValue()
    resetIvcfCache()
    setFormAtivo(setNullIfNewFormAtivoIsEqual(FormAtivoObjetivoEnum.IVCF))
  }

  const handleHabilitarPreNatal = () => {
    if (idadeGestacional > IDADE_GESTACIONAL_MAXIMA_EM_DIAS) {
      informationPreNatalSuperiorA336Dias(dataInicioGestacao)
      return
    }
    switch (tipoPreNatal) {
      case TipoPreNatal.W78_RESOLVIDO_NA_AVALIACAO: {
        informationPreNatalQuandoExisteW78ResolvidoNaAvaliacao()
        break
      }
      case TipoPreNatal.ENCERRAMENTO_GESTACAO: {
        informationPreNatalQuandoExisteDesfecho()
        break
      }
      default: {
        setFormAtivo(setNullIfNewFormAtivoIsEqual(FormAtivoObjetivoEnum.PRE_NATAL))
        break
      }
    }
  }

  const handleClick = (stateButton: FormAtivoObjetivoEnum) => () => {
    if (isBotaoPreNatalHabilitado) {
      if (stateButton === FormAtivoObjetivoEnum.PUERICULTURA) {
        return informationPuericulturaQuandoExistePreNatal()
      } else if (stateButton === FormAtivoObjetivoEnum.IVCF) {
        return informationIvcfQuandoExistePreNatal()
      } else {
        return handleDesabilitarPreNatal(stateButton)
      }
    } else if (isBotaoPuericulturaHabilitado) {
      if (stateButton === FormAtivoObjetivoEnum.PRE_NATAL) {
        return informationPreNatalQuandoExistePuericultura()
      } else {
        return handleClickPuericultura(stateButton)
      }
    } else if (isBotaoIvcfHabilitado) {
      if (hasIvcfPreenchidoEmAtendObservacaoAnterior && stateButton === FormAtivoObjetivoEnum.PRE_NATAL) {
        return informationPreNatalQuandoExisteIvcf()
      } else {
        return handleDesabilitarIvcf(stateButton)
      }
    }
    if (stateButton === FormAtivoObjetivoEnum.PRE_NATAL) {
      return handleHabilitarPreNatal()
    } else if (stateButton === FormAtivoObjetivoEnum.IVCF) {
      if (hasProblemaCondicaoDePreNatal(problemasCondicoes, hasProblemaComCiapW78AtivoPersistido)) {
        return informationIvcfQuandoExistePreNatal()
      } else {
        return handleHabilitarIvcf()
      }
    } else if (stateButton === FormAtivoObjetivoEnum.PUERICULTURA)
      if (tipoPreNatal === TipoPreNatal.ENCERRAMENTO_GESTACAO) {
        return informationPuericulturaQuandoExisteDesfecho()
      } else if (hasProblemaCondicaoDePreNatal(problemasCondicoes, hasProblemaComCiapW78AtivoPersistido)) {
        return informationPuericulturaQuandoExistePreNatal()
      }
    return setFormAtivo(setNullIfNewFormAtivoIsEqual(stateButton))
  }

  if (!acessoPuericultura && !acessoPreNatal && !acessoIvcf) {
    setFormAtivo(null) // resolve alguns problemas de troca de data nascimento
    return null
  }

  return (
    <VFlow vSpacing={0.5}>
      <Heading level={5}>Habilitar campos de</Heading>
      <VFlow vSpacing={0.5}>
        <ButtonGroup>
          <HFlow hSpacing={1} justifyContent='flex-start'>
            {acessoPreNatal && (
              <PreNatalButton
                isSelecionado={isBotaoPreNatalHabilitado || isPreNatalEmAtendimentoObservacao}
                handleClick={handleClick(FormAtivoObjetivoEnum.PRE_NATAL)}
                disabled={isLoadingPreNatalEmAtendimentoObservacao || isPreNatalEmAtendimentoObservacao}
                tooltipText={
                  isPreNatalEmAtendimentoObservacao &&
                  'Não é possível editar registros de "Pré-natal" dentro de um atendimento de observação.'
                }
              />
            )}
            {acessoPuericultura && (
              <PuericulturaButton
                puericulturaAtivo={isBotaoPuericulturaHabilitado || isPuericulturaEmAtendimentoObservacao}
                handleClick={handleClick(FormAtivoObjetivoEnum.PUERICULTURA)}
                disabled={isLoadingAcompanhamentoPuericulturaCardQuery || isPuericulturaEmAtendimentoObservacao}
                tooltipText={
                  isPuericulturaEmAtendimentoObservacao &&
                  'Não é possível editar registros de "Puericultura" dentro de um atendimento de observação.'
                }
              />
            )}
            {acessoIvcf && (
              <Tooltip
                text={
                  hasIvcfPreenchidoEmAtendObservacaoAnterior &&
                  formAtivo === FormAtivoObjetivoEnum.IVCF &&
                  'Não é possível editar o registro de IVCF-20 dentro de um atendimento de observação.'
                }
              >
                <IvcfButton
                  isIvcfAtivo={isBotaoIvcfHabilitado}
                  onClick={handleClick(FormAtivoObjetivoEnum.IVCF)}
                  disabled={
                    (hasIvcfPreenchidoEmAtendObservacaoAnterior && formAtivo === FormAtivoObjetivoEnum.IVCF) ||
                    isLoadingIvcfObservacaoData
                  }
                />
              </Tooltip>
            )}
          </HFlow>
        </ButtonGroup>

        <SwitchPanelObjetivoForm
          isLoading={isLoadingAcompanhamentoPuericulturaCardQuery}
          isReadOnly={isPuericulturaEmAtendimentoObservacao}
          hasAccess={isBotaoPuericulturaHabilitado && acessoPuericultura}
          components={{
            Field: (
              <PuericulturaField name={name.puericultura} cidadao={cidadao} loading={isLoadingPuericulturaQuery} />
            ),
            View: (
              <PuericulturaView
                cidadao={cidadao}
                dataAtendimento={dataAtendimento}
                value={acompanhamentoPuericultura}
              />
            ),
          }}
        />

        <SwitchPanelObjetivoForm
          isLoading={isLoadingPreNatalEmAtendimentoObservacao}
          isReadOnly={isPreNatalEmAtendimentoObservacao}
          hasAccess={isBotaoPreNatalHabilitado && acessoPreNatal}
          components={{
            Field: <PreNatalField name={metaPreNatal} tipoPreNatal={tipoPreNatal} metaDum={metaDum} />,
            View: <PreNatalView value={dadosPreNatal} />,
          }}
        />

        <SwitchPanelObjetivoForm
          isLoading={isLoadingIvcfObservacaoData}
          isReadOnly={hasIvcfPreenchidoEmAtendObservacaoAnterior && isBotaoIvcfHabilitado && acessoIvcf}
          hasAccess={isBotaoIvcfHabilitado && acessoIvcf}
          components={{
            Field: <IvcfField name={name.ivcf} cachePath={name.ivcfCache} />,
            View: <IvcfView values={convertIvcfQueryToView(ivcfObservacaoData?.atendimentoIndividual?.ivcf)} />,
          }}
        />
      </VFlow>
    </VFlow>
  )
}
