import ApolloClient from 'apollo-client'
import { ProcedimentoSelectModel } from 'components/form/field/select/ProcedimentoSelectField'
import createDecorator from 'final-form-calculate'
import { TipoAtendimentoProfissional, TipoEstabelecimentoEnum } from 'graphql/types.generated'
import { CidadaoAtendimentoContextModel } from 'hooks/atendimento-context/model-atendimentoContext'
import { isCboMedico } from 'util/atendimento/cboHelper'

import { Problema } from '../detail/soap/aside/types/ProblemaModel'
import { createAvaliacaoCalculations } from '../detail/soap/avaliacao/calculator-avaliacao'
import { createFinalizacaoAtendimentoCalculations } from '../detail/soap/finalizacao/calculator/calculator-finalizacao'
import { createObjetivoCalculations } from '../detail/soap/objetivo/calculator-objetivo'
import { createPlanoCalculations } from '../detail/soap/plano/calculator-plano'
import { HasAccessProcedimentos } from '../detail/soap/plano/calculator-plano-medicoes'
import { createIdadeGestacionalCalculation } from '../detail/soap/pre-natal/calculator-preNatal'
import { ProcedimentoAutomatico, RegistroAvaliacaoPuericultura } from '../model'
import { CiapCidPreNatal, meta } from './model'

export interface CidadaoCalculator
  extends Pick<
    CidadaoAtendimentoContextModel,
    'idadeEmAnos' | 'dataNascimento' | 'isGestante' | 'desejaInformarIdentidadeGenero' | 'sexo'
  > {
  problemasAtivosELatentes: Problema[]
}

export interface PermissionsCalculator {
  hasPermissionPreNatal: boolean
  hasPermissionAcompanhamentoPreNatal: boolean
  hasAccessProcedimentos: HasAccessProcedimentos
}

export interface AtendimentoIndividualCalculatorProps {
  apollo: ApolloClient<object>
  procedimentosAutomaticos: ProcedimentoAutomatico[]
  intervencoesProcedimentosAutomaticos: ProcedimentoSelectModel[]
  registroAvaliacaoPuericultura: RegistroAvaliacaoPuericultura
  cbo2002: string
  tipoAtendimentoProfissional: TipoAtendimentoProfissional
  prontuarioId: ID
  ciapCidPreNatal: CiapCidPreNatal
  dataAtendimento: Instant
  cidadao: CidadaoCalculator
  tipoEstabelecimento: TipoEstabelecimentoEnum
  permissions: PermissionsCalculator
  ultimaDumPreNatalAtivo: LocalDate
  isRegistroTardio: boolean
  isObservacaoAndResponsavel: boolean
  hasProblemaComCiapW78AtivoPersistido: boolean
}

export const createAtendimentoIndividualCalculator = (props: AtendimentoIndividualCalculatorProps) => {
  const {
    apollo,
    procedimentosAutomaticos,
    intervencoesProcedimentosAutomaticos,
    registroAvaliacaoPuericultura,
    cbo2002,
    tipoAtendimentoProfissional,
    prontuarioId,
    ciapCidPreNatal,
    dataAtendimento,
    permissions: { hasAccessProcedimentos, hasPermissionAcompanhamentoPreNatal, hasPermissionPreNatal },
    cidadao,
    tipoEstabelecimento,
    ultimaDumPreNatalAtivo,
    isRegistroTardio,
    isObservacaoAndResponsavel,
    hasProblemaComCiapW78AtivoPersistido,
  } = props

  const isMedico = isCboMedico(cbo2002)
  return createDecorator(
    ...createObjetivoCalculations(
      meta.objetivo,
      meta.avaliacao,
      cidadao.isGestante,
      hasPermissionPreNatal,
      hasProblemaComCiapW78AtivoPersistido
    ),
    ...createAvaliacaoCalculations(
      meta.objetivo,
      meta.avaliacao,
      registroAvaliacaoPuericultura,
      isMedico,
      apollo,
      prontuarioId,
      ciapCidPreNatal,
      cidadao.dataNascimento,
      dataAtendimento,
      hasPermissionPreNatal,
      cidadao.isGestante,
      hasProblemaComCiapW78AtivoPersistido,
      cidadao.problemasAtivosELatentes
    ),
    ...createPlanoCalculations({
      plano: meta.plano,
      objetivo: meta.objetivo,
      formAtivoPath: meta.objetivo.atendimentosEspecificos.formAtivo,
      medicoes: meta.objetivo.medicoes,
      puericultura: meta.objetivo.atendimentosEspecificos.puericultura,
      finalizacao: meta.finalizacao,
      procedimentosAutomaticos,
      intervencoesProcedimentosAutomaticos,
      idadeCidadaoEmAnos: cidadao.idadeEmAnos,
      hasAccessProcedimentos,
      tipoAtendimentoProfissional,
      tipoEstabelecimento,
      cbo2002,
      isRegistroTardio,
    }),
    ...createFinalizacaoAtendimentoCalculations(
      meta.finalizacao,
      meta.avaliacao.problemasECondicoes,
      apollo,
      cbo2002,
      procedimentosAutomaticos,
      tipoAtendimentoProfissional,
      hasPermissionPreNatal,
      cidadao.idadeEmAnos,
      cidadao.desejaInformarIdentidadeGenero,
      cidadao.sexo,
      tipoEstabelecimento,
      isObservacaoAndResponsavel,
      hasProblemaComCiapW78AtivoPersistido
    ),
    createIdadeGestacionalCalculation(
      meta.acompanhamentoPreNatal.idadeGestacional,
      meta.objetivo,
      meta.avaliacao,
      apollo,
      prontuarioId,
      dataAtendimento,
      hasPermissionAcompanhamentoPreNatal,
      ultimaDumPreNatalAtivo,
      cidadao.isGestante,
      hasProblemaComCiapW78AtivoPersistido
    )
  )
}
