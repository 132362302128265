/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Button, HFlow, Icon, Text, VFlow } from 'bold-ui'
import { useAccordionControl } from 'components/accordion/useAccordionControl'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { SEM_EQUIPE_ITEM } from 'components/form'
import { PageContent } from 'components/layout/PageContent'
import { LoadingIndicator } from 'components/loading'
import { PageHeaderSection } from 'components/PageHeaderSection'
import theme from 'config/theme'
import { useFlags } from 'config/useFlagsContext'
import { useTerritorioMicroareaQuery, useVisualizacaoTerritorialQuery } from 'graphql/hooks.generated'
import { PageParams, TipoEnderecoEnum } from 'graphql/types.generated'
import { Fragment, useRef, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router'
import { v4 } from 'uuid'
import { useCadastroImovelCache } from 'view/cadastro-imovel/hooks/useCadastroImovelCache'
import { tipoEnderecoIndigena } from 'view/cadastro-imovel/model-cadastroImovel'
import { useSaudeIndigenaAuthorization } from 'view/cidadao/authorization/useSaudeIndigenaAuthorization'

import { MicroareaLogradouroPagedList } from './components/list/MicroareaLogradouroPagedList'
import { RelatorioAcompanhamentoTerritorio } from './components/RelatorioAcompanhamentoTerritorio'
import VisualizacaoTerritorialMicroareaTabs from './components/VisualizacaoTerritorialMicroareaTabs'
import {
  convertAnimaisImovelFilterToFormModel,
  convertVisualizacaoFormModelToInput,
  convertVisualizacaoMicroareaFilterToInput,
} from './converter'
import { useMicroareasVisualizacao } from './hooks/useMicroareasVisualizacao'
import { useVisualizacaoCache } from './hooks/useVisualizacaoCache'
import {
  TerritorioModel,
  VisualizacaoFilterModel,
  VisualizacaoMicroareasFilterFormModel,
  VisualizacaoTerritorialBuscaFormModel,
} from './model'
import { VisualizacaoTerritorialBuscaForm } from './VisualizacaoTerritorialBuscaForm'

export function VisualizacaoTerritorialView() {
  const { acesso, profissional } = useAcessoLotacaoOrEstagio()
  const unidadeSaude = acesso?.unidadeSaude
  const equipe = acesso?.equipe

  const match = useRouteMatch()
  const history = useHistory()

  const { CADASTRO_IMOVEL_ENABLED } = useFlags()
  const { hasTerritorioIndigenaPermission } = useSaudeIndigenaAuthorization()

  const territorioEquipe: VisualizacaoTerritorialBuscaFormModel = {
    unidadeSaude: unidadeSaude,
    equipe: equipe ?? SEM_EQUIPE_ITEM,
    tipoEndereco: hasTerritorioIndigenaPermission ? TipoEnderecoEnum.ALDEIA : TipoEnderecoEnum.LOGRADOURO,
  }

  const progressToken = useRef(v4())
  const { deleteCacheState } = useCadastroImovelCache(acesso.id)

  const {
    visualizacaoCacheState,
    setVisualizacaoCacheState,
    deleteVisualizacaoCacheState,
    shouldUseCache,
  } = useVisualizacaoCache(acesso?.id)

  const [visualizacaoFilter, setVisualizacaoFilter] = useState<VisualizacaoFilterModel>(
    shouldUseCache ? visualizacaoCacheState.visualizacaoFilter : { territorioEquipeFilter: territorioEquipe }
  )

  const territorioEquipeFilter = visualizacaoFilter?.territorioEquipeFilter || territorioEquipe
  const skipQueryFirstRender =
    tipoEnderecoIndigena.includes(territorioEquipeFilter.tipoEndereco) &&
    !territorioEquipeFilter.dsei &&
    !territorioEquipeFilter.poloBase

  const { data, loading: loadingTerritorioEquipe } = useVisualizacaoTerritorialQuery({
    variables: {
      input: {
        ...convertVisualizacaoFormModelToInput(territorioEquipeFilter),
      },
    },
    skip: skipQueryFirstRender,
  })

  const microareas = data.territorioEquipe?.microareas
  const poloBase = data.territorioEquipe?.poloBase
  const hasLogradouros = microareas?.isNotEmpty() || !!poloBase

  const { handleAccordionItemClick, isExpanded, resetExpandedItems, expandedItems } = useAccordionControl({
    allowMultipleExpanded: true,
    initialExpandedItems: shouldUseCache ? visualizacaoCacheState?.expandedItems : [],
  })

  const {
    currentMicroareaTab,
    handleMicroareaTabClick,
    setRenderMicroareaControl,
    setCurrentMicroareaTab,
  } = useMicroareasVisualizacao(visualizacaoCacheState?.currentMicroareaTab, shouldUseCache, resetExpandedItems)
  const [pageParams, setPageParams] = useState<{ pageParams: PageParams }>(
    shouldUseCache ? { pageParams: visualizacaoCacheState?.pageParams } : initialPageParams
  )

  const handleStopRenderMicroareaCache = () => {
    resetExpandedItems()
    setRenderMicroareaControl((state) => ({ ...state, renderMicroareaCache: false }))
  }

  const onFiltrarMicroareas = (formValues: VisualizacaoMicroareasFilterFormModel) => {
    handleStopRenderMicroareaCache()
    setVisualizacaoFilter((state) => ({
      ...state,
      territorioMicroareasFilter: {
        ...formValues,
        isCadastroAtualizadoMenosDeUmAno: formValues?.isCadastroAtualizadoMenosDeUmAno?.atualizadoHaMenosDeUmAno,
        isCadastroCompleto: formValues?.isCadastroCompleto?.isCadastroCompleto,
        isImovelVisitadoMenosDeUmMes: formValues?.isImovelVisitadoMenosDeUmMes?.ultimaVisitaHaMenosDeUmMes,
        animaisImovel: convertAnimaisImovelFilterToFormModel(formValues?.animaisImovelFilter),
        condicoesMoradia: formValues?.condicoesMoradia,
        tipoImovel: formValues?.tipoImovel,
      },
    }))
  }

  const onBuscarTerritorioEquipe = (formValues: VisualizacaoTerritorialBuscaFormModel) => {
    handleStopRenderMicroareaCache()
    setCurrentMicroareaTab(undefined)
    setVisualizacaoFilter((state) => ({ ...state, territorioEquipeFilter: { ...formValues } }))
  }

  const saveVisualizacaoCacheState = () =>
    setVisualizacaoCacheState({
      visualizacaoFilter,
      currentMicroareaTab,
      pageParams: pageParams.pageParams,
      expandedItems,
      isRedirect: true,
    })

  const isPoloBaseTab = poloBase ? poloBase.id === currentMicroareaTab : false

  const currentTab =
    microareas?.includes(currentMicroareaTab) || isPoloBaseTab
      ? currentMicroareaTab
      : poloBase
      ? poloBase.id
      : microareas?.[0]

  const isPoloBaseCurrentTab = poloBase ? poloBase.id === currentTab : false

  const microareaInput = {
    cnes: unidadeSaude.cnes,
    ine: equipe?.ine,
    tipoEndereco: territorioEquipeFilter.tipoEndereco,
    ...convertVisualizacaoFormModelToInput(visualizacaoFilter?.territorioEquipeFilter),
    ...convertVisualizacaoMicroareaFilterToInput(visualizacaoFilter.territorioMicroareasFilter),
    microarea: isPoloBaseCurrentTab ? undefined : currentTab,
    pageParams: pageParams.pageParams,
  }

  const { data: territorioMicroareaData, loading: loadingTerritorioMicroarea, refetch } = useTerritorioMicroareaQuery({
    variables: {
      input: microareaInput,
    },
    skip:
      (territorioEquipeFilter.tipoEndereco === TipoEnderecoEnum.LOGRADOURO && microareaInput.microarea === undefined) ||
      currentTab === undefined,
    fetchPolicy: 'network-only',
  })

  const handleRefreshMicroarea = () => {
    refetch({ input: microareaInput })
  }

  const handleClickAdicionarImovel = () => {
    deleteCacheState(false)
    saveVisualizacaoCacheState()
    setTimeout(() => history.push(`${match.url}/cadastrarImovel?redirect=true`))
  }

  const territorioModel: TerritorioModel = {
    cnes: unidadeSaude.cnes,
    ine: equipe?.ine,
    microarea: isPoloBaseCurrentTab ? undefined : currentTab,
    ...convertVisualizacaoMicroareaFilterToInput(visualizacaoFilter?.territorioMicroareasFilter),
    ...convertVisualizacaoFormModelToInput(visualizacaoFilter.territorioEquipeFilter),
  }

  const hasImoveisLogradouro = territorioMicroareaData?.territorioMicroarea?.logradouros?.content.isNotEmpty()

  const isTerritorioFiltroDiferenteUsuarioLogado =
    unidadeSaude.cnes !== territorioEquipeFilter.unidadeSaude.cnes || equipe?.ine !== territorioEquipeFilter.equipe?.ine

  const isTerritorioFiltradoAtivo =
    territorioEquipeFilter.equipe?.ativo !== false && territorioEquipeFilter.unidadeSaude.ativo !== false

  return (
    <Fragment>
      <PageHeaderSection title='Acompanhamento do território' />

      <PageContent type='filled'>
        <VFlow vSpacing={3}>
          <VisualizacaoTerritorialBuscaForm
            onSubmit={onBuscarTerritorioEquipe}
            unidadeSaude={unidadeSaude}
            equipe={equipe}
            initialValues={territorioEquipeFilter}
          />

          {CADASTRO_IMOVEL_ENABLED && (
            <Button
              kind='primary'
              skin='ghost'
              onClick={handleClickAdicionarImovel}
              style={css`
                width: 100%;
                border: 1px solid ${theme.pallete.primary.c40};
                margin-top: 1.5rem;
              `}
            >
              <HFlow>
                <Icon icon='plus' />
                Adicionar imóvel
              </HFlow>
            </Button>
          )}

          {hasLogradouros ? (
            <VisualizacaoTerritorialMicroareaTabs
              microareas={data?.territorioEquipe.microareas}
              poloBase={data?.territorioEquipe.poloBase}
              currentMicroareaTab={currentTab}
              onTabClick={handleMicroareaTabClick}
            />
          ) : loadingTerritorioEquipe ? (
            <LoadingIndicator />
          ) : (
            <Text fontWeight='normal' variant='h4' fontStyle='italic'>
              Ainda não existem imóveis cadastrados sob essa responsabilidade. Clique no botão acima para iniciar um
              novo registro.
            </Text>
          )}
        </VFlow>
        {loadingTerritorioMicroarea && !loadingTerritorioEquipe ? (
          <LoadingIndicator />
        ) : (
          currentTab &&
          hasLogradouros && (
            <Fragment>
              <MicroareaLogradouroPagedList
                progressToken={progressToken.current}
                logradouros={territorioMicroareaData?.territorioMicroarea}
                territorio={territorioModel}
                onAccordionItemClick={handleAccordionItemClick}
                isExpanded={isExpanded}
                resetExpandedItems={resetExpandedItems}
                onFiltrarMicroareas={onFiltrarMicroareas}
                microareasFilter={visualizacaoFilter?.territorioMicroareasFilter}
                lotacaoId={acesso?.id}
                municipioId={acesso?.municipio.id}
                profissionalId={profissional?.id}
                saveVisualizacaoCacheState={saveVisualizacaoCacheState}
                onChangePageParams={setPageParams}
                deleteVisualizacaoCacheState={deleteVisualizacaoCacheState}
                onRefreshMicroarea={handleRefreshMicroarea}
                tipoEndereco={territorioEquipeFilter.tipoEndereco}
                isTerritorioFiltroDiferenteUsuarioLogado={isTerritorioFiltroDiferenteUsuarioLogado}
                canCboWriteFicha={acesso.cbo.actions.cadastroDomiciliar.enabled}
                isTerritorioFiltradoAtivo={isTerritorioFiltradoAtivo}
              />
              <RelatorioAcompanhamentoTerritorio
                usuarioId={profissional.usuario.id}
                microareaInput={microareaInput}
                hasImoveisLogradouros={hasImoveisLogradouro}
                hasTerritorioIndigenaPermission={hasTerritorioIndigenaPermission}
              />
            </Fragment>
          )
        )}
      </PageContent>
    </Fragment>
  )
}

const initialPageParams = { pageParams: { page: 0, size: 10 } }
