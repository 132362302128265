/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { Alert, Button, Cell, Grid, Heading, HFlow, Text, Tooltip, VFlow } from 'bold-ui'
import { useAlert } from 'components/alert'
import { useAcessoLotacaoOrEstagio } from 'components/auth/useAcessoLotacao'
import { useErrorHandler } from 'components/error'
import {
  CheckboxField,
  DateField,
  EnderecoFieldGroup,
  EnderecoFieldGroupModel,
  Form,
  FormValueSpy,
  NumberField,
  RadioGroupField,
  SubmitButton,
  SwitchField,
  TelefoneField,
} from 'components/form'
import { EnderecoIndigenaFieldGroup } from 'components/form/field/enderecoindigena/EnderecoIndigenaFieldGroup'
import {
  TipoDomicilioSelectField,
  TipoImovelSelectField,
} from 'components/form/field/select/CondicoesMoradiaSelectFields'
import { TipoEnergiaEletricaSelectField } from 'components/form/field/select/CondicoesMoradiaSelectFields/TipoEnergiaEletricaSelectField'
import { SimNaoRadioGroupField } from 'components/form/field/SimNaoRadioGroupField'
import { RequiredLabel } from 'components/form/final-form/RequiredLabel/RequiredLabel'
import { InfoIcon } from 'components/InfoIcon'
import { ACOMPANHAMENTO_TERRITORIO_PATH } from 'components/layout/SideMenu/SideMenuItems'
import { LotacaoResponsavelSection } from 'components/lotacao-responsavel/LotacaoResponsavelSection'
import { confirm } from 'components/modals/confirm'
import { subMonths } from 'date-fns'
import {
  useCidadaoQuery,
  useImoveisCadastrosDuplicadosLazyQuery,
  useIsLotacaoActiveQuery,
  useSalvarImovelMutation,
} from 'graphql/hooks.generated'
import { ImovelDto, TerritorioMicroareaQueryInput, TipoEnderecoEnum } from 'graphql/types.generated'
import { useServerTime } from 'hooks/useServerTime'
import { useUrlQueryParams } from 'hooks/useUrlQueryParams'
import qs from 'qs'
import { Fragment, useMemo, useRef, useState } from 'react'
import { FormRenderProps } from 'react-final-form'
import { useHistory, useLocation } from 'react-router'
import { metaPath } from 'util/metaPath'
import { useSaudeIndigenaAuthorization } from 'view/cidadao/authorization/useSaudeIndigenaAuthorization'
import { UrlParams } from 'view/cidadao/create/ModalStatusSincronizacaoCadsus'
import { ImovelUrlParams } from 'view/visualizacao-imovel/model-visualizacao-imovel'
import { getVisualizacaoImovelCallbackUrl } from 'view/visualizacao-imovel/utils-visualizacao-imovel'
import { useVisualizacaoCache } from 'view/visualizacaoTerritorial/hooks/useVisualizacaoCache'

import { createCadastroImovelCalculator } from '../calculator-cadastroImovel'
import { convertImovelFormModelToImovelInput } from '../converter-cadastroImovel'
import { useCadastroImovelCache } from '../hooks/useCadastroImovelCache'
import { ImovelFormModel, tipoEnderecoIndigena, tipoEnderecoOptions } from '../model-cadastroImovel'
import {
  isTipoImovelDomicilio,
  isTipoImovelResidencial,
  isTipoImovelResidencialExcetoDomicilio,
  simNaoEnumToBooleanWrapper,
  verifyImoveisDuplicados,
} from '../utils-cadastroImovel'
import { validateFamilias, validateImovelForm } from '../validator-cadastroImovel'
import { AnimaisDomicilioFieldGroup } from './AnimaisDomicilioFieldGroup'
import { CondicoesDeMoradiaFieldGroup } from './CondicoesDeMoradiaFieldGroup'
import { FamiliaImovelSection } from './FamiliaImovelSection'
import { InstituicaoPermanenciaSection } from './InstituicaoPermanenciaSection'
import { LogradouroCadastroDuplicadoModal } from './modal/LogradouroCadastroDuplicadoModal'
import { RecusaCadastroModal } from './modal/RecusaCadastroModal'

interface ImovelFormProps {
  initialValues?: ImovelFormModel
}

const meta = metaPath<ImovelFormModel>()

export function ImovelForm({ initialValues }: ImovelFormProps) {
  const [recusaCadastroModalOpen, setRecusaCadastroModalOpen] = useState(false)

  const history = useHistory()

  const location = useLocation()

  const decorators = useMemo(() => [createCadastroImovelCalculator(meta)], [])

  const { hasTerritorioIndigenaPermission } = useSaudeIndigenaAuthorization()

  const { getServerTimeNow } = useServerTime()
  const serverTime = getServerTimeNow()

  const handleRejection = useErrorHandler()

  const alert = useAlert()

  const [salvarImovel] = useSalvarImovelMutation()

  const [executeQueryCadastrosDuplicados] = useImoveisCadastrosDuplicadosLazyQuery({
    onCompleted: (data) => {
      handleQueryResult(data.territorioLogradouro as ImovelDto[])
    },
  })

  const { acesso } = useAcessoLotacaoOrEstagio()

  const { cidadaoId, redirect, endereco } = useUrlQueryParams<ImovelUrlParams>({ parameterLimit: 5 })

  const urlParams: UrlParams = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })

  const { cacheState, shouldRenderCache, setCacheState, deleteCacheState } = useCadastroImovelCache(
    acesso.id,
    redirect,
    cidadaoId,
    endereco
  )

  const cachedForm = useRef(
    shouldRenderCache
      ? { ...initialValues, ...cacheState?.form }
      : {
          ...initialValues,
          tipoEndereco: cacheState?.form?.tipoEndereco,
          stMicroareaPoloBase: cacheState?.form?.stMicroareaPoloBase,
          endereco: cacheState?.form?.endereco,
          enderecoIndigena: cacheState?.form?.enderecoIndigena,
          microarea: cacheState?.form?.microarea === 'FA' ? null : cacheState?.form?.microarea,
          statusForaArea: cacheState?.form?.microarea === 'FA',
        }
  )

  const { visualizacaoCacheState, setVisualizacaoCacheState, deleteVisualizacaoCacheState } = useVisualizacaoCache(
    acesso?.id
  )

  const initialFormValues = useRef(shouldRenderCache ? cachedForm.current : initialValues)

  const familiaSectionRef = useRef<HTMLDivElement>()

  const cachedDirtyFields = useRef(shouldRenderCache ? cacheState?.dirtyFields : {})

  const imoveisDuplicados = useRef<ImovelDto[]>([])

  const previousEndereco = useRef<EnderecoFieldGroupModel>(
    shouldRenderCache ? cacheState?.previousEndereco : ({} as EnderecoFieldGroupModel)
  )

  const { data: cidadaoData } = useCidadaoQuery({
    fetchPolicy: 'cache-first',
    variables: { id: cidadaoId },
    skip: !cidadaoId || !shouldRenderCache,
  })

  const visualizacaoCallbackUrl = useMemo(() => getVisualizacaoImovelCallbackUrl(initialValues.id, redirect), [
    initialValues.id,
    redirect,
  ])

  const handleCancel = (hasChanges: boolean) => {
    let visualizacaoAcompanhamentoUrl: string

    if (!initialValues.id || urlParams?.callbackUrl === '/acompanhamentos/territorio/cadastrarImovel') {
      visualizacaoAcompanhamentoUrl = `${ACOMPANHAMENTO_TERRITORIO_PATH}?redirect=true`
    } else {
      visualizacaoAcompanhamentoUrl = visualizacaoCallbackUrl
    }

    if (hasChanges) {
      confirmCancelar(visualizacaoAcompanhamentoUrl)
    } else {
      deleteCacheState()
      history.push(visualizacaoAcompanhamentoUrl)
    }
  }

  const [isImoveisDuplicadosModalOpen, setIsImoveisDuplicadosModalOpen] = useState<boolean>(false)

  const handleSubmitNoChanges = (formProps: FormRenderProps<ImovelFormModel>) => {
    confirm({
      title: 'Nenhuma alteração efetuada.',
      body:
        'Deseja atualizar o cadastro mesmo sem alterações nos dados? Isso ainda será contabilizado como produção de cadastro.',
      onCancel: () => history.push(visualizacaoCallbackUrl),
      onConfirm: () => formProps.handleSubmit(),
      confirmLabel: 'Confirmar',
      cancelLabel: 'Sair sem salvar',
    })()
  }

  const confirmCancelar = (visualizacaoAcompanhamentoUrl: string) => {
    confirm({
      title: 'Deseja cancelar o cadastro?',
      body: 'As alterações realizadas serão perdidas.',
      onConfirm: () => {
        deleteCacheState()
        setTimeout(() => history.push(visualizacaoAcompanhamentoUrl))
      },
      confirmLabel: 'Sim',
      cancelLabel: 'Não',
    })()
  }

  const handleSubmit = (input: ImovelFormModel) => {
    const errorMessage = input?.familias ? validateFamilias(input.familias) : null

    const visualizacaoCallbackUrl =
      !input.id && !initialValues.id
        ? `${ACOMPANHAMENTO_TERRITORIO_PATH}?redirect=true`
        : getVisualizacaoImovelCallbackUrl(input.id ?? initialValues.id, redirect)

    if (errorMessage) {
      alert('danger', errorMessage)
    } else {
      return salvarImovel({
        variables: {
          input: convertImovelFormModelToImovelInput(input),
        },
      })
        .then(() => {
          updateCacheAcompanhamentoMudancaTerritorio(input)
          deleteCacheState()
          setTimeout(() => history.push(visualizacaoCallbackUrl))
          alert('success', 'Imóvel salvo com sucesso.')
        })
        .catch(handleRejection)
    }
  }

  const updateCacheAcompanhamentoMudancaTerritorio = (input: ImovelFormModel) => {
    if (!visualizacaoCacheState) return
    const {
      unidadeSaude: { cnes },
      equipe: { ine },
    } = visualizacaoCacheState?.visualizacaoFilter?.territorioEquipeFilter || {}
    const { unidadeSaude, equipe } = input.responsabilidade.lotacao

    const hasIneChanged = ine !== equipe?.ine && ine === '-' && equipe?.ine !== undefined
    const hasCnesChanged = cnes !== unidadeSaude?.cnes

    const hasMicroareaChanged = visualizacaoCacheState?.currentMicroareaTab !== input.microarea

    if (hasIneChanged || hasCnesChanged) {
      deleteVisualizacaoCacheState()
    } else if (hasMicroareaChanged) {
      setVisualizacaoCacheState((previous) => ({
        ...previous,
        currentMicroareaTab: input.statusForaArea ? 'FA' : input.microarea,
        isRedirect: true,
      }))
    }
  }

  const handleFormChange = (form: ImovelFormModel, dirtyFields: { [key: string]: boolean }) => {
    if (!initialValues.id && verifyImoveisDuplicados(previousEndereco.current, form.endereco)) {
      previousEndereco.current = { ...form.endereco }
      setCacheState((previous) => ({
        ...previous,
        previousEndereco: previousEndereco.current,
      }))
      handleRequestImoveisDuplicados(form.endereco)
    }

    setCacheState((previous) => {
      return {
        ...previous,
        familiaCidadaoModalState: previous?.familiaCidadaoModalState
          ? previous.familiaCidadaoModalState
          : {
              cadastroCidadaoModal: {
                modalOpen: false,
              },
              cadastroFamiliaModal: {
                modalOpen: false,
                editionId: null,
              },
              atualizacaoCidadao: false,
            },
        listaLogradouroState: previous?.listaLogradouroState
          ? previous.listaLogradouroState
          : {
              fillEnderecoFields: false,
            },
        form,
        dirtyFields,
      }
    })
  }

  const verifyFormChanges = (dirtyFields: { [key: string]: boolean }, values: ImovelFormModel) => {
    const combinedDirtyFields = { ...cachedDirtyFields.current, ...dirtyFields }

    return Object.keys(combinedDirtyFields).some(
      (property) =>
        property !== 'dataColeta' &&
        !((values.statusForaArea || values.stMicroareaPoloBase) && property === 'microarea')
    )
  }

  const handleRequestImoveisDuplicados = (endereco: EnderecoFieldGroupModel) => {
    const input: TerritorioMicroareaQueryInput = {
      bairro: endereco.bairro.nome,
      tipoLogradouroId: endereco.tipoLogradouro.id,
      logradouro: endereco.logradouro.nome,
      cep: endereco.cep,
      ufId: endereco.uf.id,
      municipioId: endereco.municipio.id,
      numero: endereco.numero,
      semNumero: endereco.semNumero,
      complemento: endereco.complemento,
      semComplemento: !endereco.complemento,
      tipoEndereco: TipoEnderecoEnum.LOGRADOURO,
      useFilterLikeDiatrics: false,
    }

    executeQueryCadastrosDuplicados({ variables: { input } })
  }

  const handleQueryResult = (territorioLogradouro: ImovelDto[]) => {
    if (territorioLogradouro?.length > 0) {
      imoveisDuplicados.current = territorioLogradouro
      setIsImoveisDuplicadosModalOpen(true)
    } else {
      imoveisDuplicados.current = []
    }
  }

  const { data: isLotacaoActiveData } = useIsLotacaoActiveQuery({
    fetchPolicy: 'network-only',
    variables: {
      input: {
        cnes: cachedForm.current?.responsabilidade?.lotacao?.unidadeSaude?.cnes,
        cns: cachedForm.current?.responsabilidade?.lotacao?.profissional?.cns,
        cbo2002: cachedForm.current?.responsabilidade?.lotacao?.cbo?.cbo2002,
        ine: cachedForm.current?.responsabilidade?.lotacao?.equipe?.ine,
        userLocalidadeId:
          cachedForm.current?.responsabilidade?.lotacao?.unidadeSaude?.endereco?.municipio?.id ?? acesso.municipio.id,
      },
    },
    skip: !cachedForm.current?.responsabilidade,
  })

  const renderForm = (formProps: FormRenderProps<ImovelFormModel>) => {
    const { values, dirtyFields } = formProps
    const isResidencialExcetoDomicilio = isTipoImovelResidencialExcetoDomicilio(values?.tipoImovel)
    const isResidencial = isTipoImovelResidencial(values?.tipoImovel)
    const isDomicilio = isTipoImovelDomicilio(values?.tipoImovel)
    const isEnderecoLogradouro = !tipoEnderecoIndigena.includes(values?.tipoEndereco)
    const isEnderecoIndigena = tipoEnderecoIndigena.includes(values?.tipoEndereco)
    const hasEnergiaEletrica = simNaoEnumToBooleanWrapper(values?.condicoesMoradia?.isEnergiaEletricaDisponivel)
    const disableOrigemEnergiaEletrica = !hasEnergiaEletrica || !isResidencial
    const hasChanges = verifyFormChanges(dirtyFields, values)
    const isLotacaoActive = isLotacaoActiveData?.isLotacaoActive

    const isUnidadeSaudeEquipeDiferenteAcesso =
      values.responsabilidade.lotacao.equipe?.ine !== acesso.equipe?.ine ||
      values.responsabilidade.lotacao.unidadeSaude.cnes !== acesso.unidadeSaude.cnes

    return (
      <Fragment>
        <FormValueSpy<ImovelFormModel> onChange={(formValues) => handleFormChange(formValues, dirtyFields)} />
        <VFlow>
          <Grid>
            <Cell size={12}>
              <VFlow>
                <Heading
                  level={2}
                  style={css`
                    margin-top: 1.5rem;
                  `}
                >
                  Responsabilidade de acompanhamento
                </Heading>
                <LotacaoResponsavelSection
                  name={meta.responsabilidade}
                  isCadastroImovel
                  isLotacaoActive={isLotacaoActive}
                  loadItemsOnOpen={false}
                />
              </VFlow>
            </Cell>
            {isLotacaoActive === false && (
              <Cell size={12}>
                <Alert type='danger' inline style={{ paddingTop: '0.25rem', paddingBottom: '0.25rem' }}>
                  O responsável informado possui uma lotação inativa. Atualize para a nova lotação alterando o
                  responsável.
                </Alert>
              </Cell>
            )}
            <Cell size={6}>
              {isUnidadeSaudeEquipeDiferenteAcesso && (
                <Alert type='info' inline style={{ paddingTop: '0.25rem', paddingBottom: '0.25rem' }}>
                  A lotação selecionada definirá a responsabilidade de acompanhamento do imóvel e seus moradores para
                  uma equipe diferente da sua, logo, ele só será exibido Acompanhamento do Território da equipe
                  selecionada.
                </Alert>
              )}
            </Cell>
            <Cell size={6} />
            <Cell size={2}>
              <HFlow hSpacing={0.2}>
                <NumberField
                  name={meta.microarea}
                  label={
                    <HFlow hSpacing={0.5} alignItems='center'>
                      {!(values?.statusForaArea || values?.stMicroareaPoloBase) ? (
                        <RequiredLabel label='Microarea' />
                      ) : (
                        <Text>Microarea</Text>
                      )}
                      {isEnderecoIndigena && (
                        <InfoIcon
                          icon='infoCircleFilled'
                          text='A microárea representa uma subdivisão do Polo base. Se o imóvel não faz parte de microárea, selecione a opção "Polo base" '
                        />
                      )}
                    </HFlow>
                  }
                  maxLength={2}
                  disabled={values?.statusForaArea || values.stMicroareaPoloBase}
                />
              </HFlow>
            </Cell>
            <Cell
              size={2}
              style={css`
                margin-top: 1.8rem;
                margin-left: -1.5rem;
              `}
            >
              {isEnderecoIndigena ? (
                <CheckboxField name={meta.stMicroareaPoloBase} label='Polo base' required={!values?.microarea} />
              ) : (
                <CheckboxField name={meta.statusForaArea} label='Fora de área' required={!values?.microarea} />
              )}
            </Cell>
            <Cell size={8} />
            <Cell size={2}>
              <DateField
                name={meta.dataColeta}
                label='Data de coleta'
                icon='calendarOutline'
                maxDate={serverTime}
                minDate={subMonths(serverTime, 12)}
                required
              />
            </Cell>
            <Cell size={10} />
            <Cell
              size={3}
              style={css`
                margin-top: 3rem;
              `}
            >
              <Heading level={1}>Endereço</Heading>
            </Cell>
            <Cell size={9} />
            {hasTerritorioIndigenaPermission && (
              <Cell size={3}>
                <RadioGroupField
                  label='Tipo de endereço'
                  name={meta.tipoEndereco}
                  options={tipoEnderecoOptions}
                  required
                />
              </Cell>
            )}
            {isEnderecoLogradouro && (
              <Cell size={12}>
                <EnderecoFieldGroup
                  isCepRequired
                  name={meta.endereco}
                  formProps={formProps}
                  formModelValue={values}
                  renderTipoLogradouro
                />
              </Cell>
            )}
            {isEnderecoIndigena && (
              <Cell size={12}>
                <EnderecoIndigenaFieldGroup
                  name={meta.enderecoIndigena}
                  formProps={formProps}
                  formModelValue={values}
                />
              </Cell>
            )}
            <Cell
              size={12}
              style={css`
                margin-top: 3rem;
              `}
            >
              <Heading level={1}>Imóvel</Heading>
            </Cell>
            <Cell size={3}>
              <Tooltip
                placement='bottom'
                style={css`
                  margin-top: 0.5rem;
                `}
                text={
                  values?.possuiFamiliasSalvas
                    ? 'Não pode ser alterado enquanto houver famílias e moradores no domicílio.'
                    : ''
                }
              >
                <TipoImovelSelectField
                  name={meta.tipoImovel}
                  label='Tipo do imóvel'
                  disabled={values?.possuiFamiliasSalvas}
                  required
                />
              </Tooltip>
            </Cell>
            <Cell size={3}>
              <TipoDomicilioSelectField
                name={meta.condicoesMoradia.tipoDomicilio}
                label='Tipo do domicílio'
                disabled={!isDomicilio || values.statusRecusa}
              />
            </Cell>
            <Cell size={2}>
              <NumberField
                name={meta.numeroMoradores}
                label='Número de moradores'
                min={0}
                max={9999}
                disabled={!isTipoImovelResidencial(values?.tipoImovel) || values.statusRecusa}
              />
            </Cell>
            <Cell size={4} />
            <Cell
              size={3}
              style={css`
                margin-top: 3rem;
              `}
            >
              <Heading level={1}>Contato</Heading>
            </Cell>
            <Cell size={9} />
            <Cell size={3}>
              <TelefoneField name={meta.telefoneContato} label='Telefone de contato' autoComplete='none' />
            </Cell>
            <Cell size={3}>
              <TelefoneField
                name={meta.telefoneResidencial}
                label='Telefone residencial'
                disabled={!isDomicilio}
                autoComplete='none'
              />
            </Cell>
            <Cell size={12} />
            <Cell size={12}>
              <Tooltip
                placement='bottom'
                style={css`
                  margin-top: 0.5rem;
                `}
                text={
                  values?.possuiFamiliasSalvas
                    ? 'Não é permitido informar recusa para um imóvel com famílias cadastradas.'
                    : ''
                }
              >
                <SwitchField
                  name={meta.statusRecusa}
                  label={`Cidadão recusou ${
                    values.id != null ? 'a atualização' : 'o cadastro'
                  } por meio do Termo de Recusa do Cadastro.`}
                  disabled={!isTipoImovelResidencial(values?.tipoImovel) || values?.possuiFamiliasSalvas}
                  onChange={() => setRecusaCadastroModalOpen(!values.statusRecusa)}
                />
              </Tooltip>
            </Cell>
            <Cell size={12}>
              <CondicoesDeMoradiaFieldGroup
                name={meta.condicoesMoradia}
                isResidencial={isResidencial}
                isResidencialExcetoDomicilio={isResidencialExcetoDomicilio}
                disabled={values.statusRecusa}
              />
            </Cell>
            <Cell size={2}>
              <SimNaoRadioGroupField
                name={meta.condicoesMoradia.isEnergiaEletricaDisponivel}
                label='Possui energia elétrica?'
                disabled={!isResidencial || values.statusRecusa}
              />
            </Cell>
            <Cell size={4}>
              <TipoEnergiaEletricaSelectField
                name={meta.condicoesMoradia.tipoOrigemEnergiaEletrica}
                label='Tipo de energia elétrica'
                disabled={disableOrigemEnergiaEletrica || values.statusRecusa}
              />
            </Cell>
            <Cell size={5} />
            <AnimaisDomicilioFieldGroup
              name={meta.animaisNoDomicilio}
              isDomicilio={isDomicilio}
              disabled={values.statusRecusa}
            />
            <Cell size={12} />
            <Cell size={12} />
            {isResidencialExcetoDomicilio && (
              <InstituicaoPermanenciaSection
                meta={meta.instituicaoPermanencia}
                isRecusaCadastro={values.statusRecusa}
              />
            )}
            {isDomicilio && (
              <Cell
                size={12}
                style={css`
                  margin-top: 3rem;
                `}
              >
                <div ref={familiaSectionRef}>
                  <FamiliaImovelSection
                    cacheState={cacheState}
                    shouldRenderCache={shouldRenderCache}
                    cidadaoModal={cidadaoData?.cidadao}
                    name={meta.familias}
                    dataColeta={values?.dataColeta}
                    disabled={values.statusRecusa}
                    onChange={setCacheState}
                    onScrollAfterRedirect={() => familiaSectionRef.current?.scrollIntoView({ behavior: 'smooth' })}
                  />
                </div>
              </Cell>
            )}
          </Grid>
          <HFlow
            justifyContent='flex-end'
            style={css`
              margin-top: 3rem;
            `}
          >
            <Fragment>
              <Button onClick={() => handleCancel(hasChanges)}>Cancelar</Button>
              <SubmitButton
                kind='primary'
                handleSubmit={
                  cacheState?.form?.id && !hasChanges ? () => handleSubmitNoChanges(formProps) : formProps.handleSubmit
                }
              >
                Salvar
              </SubmitButton>
            </Fragment>
          </HFlow>
        </VFlow>
        {recusaCadastroModalOpen && (
          <RecusaCadastroModal open={recusaCadastroModalOpen} onClose={() => setRecusaCadastroModalOpen(false)} />
        )}
        {isImoveisDuplicadosModalOpen && (
          <LogradouroCadastroDuplicadoModal
            isModalOpen={isImoveisDuplicadosModalOpen}
            onClose={() => {
              setIsImoveisDuplicadosModalOpen(false)
            }}
            imoveis={imoveisDuplicados.current}
            deleteCacheState={deleteCacheState}
          />
        )}
      </Fragment>
    )
  }

  return (
    <Form<ImovelFormModel>
      onSubmit={handleSubmit}
      render={renderForm}
      initialValues={initialFormValues.current}
      validate={validateImovelForm(serverTime)}
      decorators={decorators}
    />
  )
}
